<template>
  <b-card>
    <care-pairing @pair-sub-complete="done" />
  </b-card>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import CarePairing from '@/components/CarePairing.vue'

export default {
  name: 'CarePairingView',
  components: {
    BCard,
    CarePairing,
  },
  methods: {
    done() {
      this.$router.replace({
        name: 'home',
      })
    },
  },
}
</script>

<style scoped>
</style>
